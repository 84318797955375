/*import plugins*/
import ENDPOINTS from "@/plugins/axios/endpoints";

const reservationTypes = {
  namespaced: true,

  /*Start state*/
  state: {
    reservationTypeUuid: "",
    reservationTypeName: "",
    reservationTypeMaxTime: null,
    maxPersons: null,
    numberPersons: null,

    reservationTypes: [],
  },
  /*End state*/

  /*Start getters*/
  getters: {
    reservationTypes: (state) => state.reservationTypes,

    reservationTypeUuid: (state) => state.reservationTypeUuid,

    maxPersons: (state) => state.maxPersons,

    skipTables: (state) => {
      const skipTablesVal = state.reservationTypes.find(
        (reserve) => reserve.uuid === state.reservationTypeUuid
      );

      if (
        typeof skipTablesVal === "object" &&
        (skipTablesVal.skipTable == null || skipTablesVal.skipTable == false)
      ) {
        return false;
      } else {
        return true;
      }
    },

    skipItems: (state) => {
      const skipItemsVal = state.reservationTypes.find(
        (reserve) => reserve.uuid === state.reservationTypeUuid
      );

      if (
        typeof skipItemsVal === "object" &&
        (skipItemsVal.skipOrders == null || skipItemsVal.skipOrders == false)
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  /*End getters*/

  /*Start mutations*/
  mutations: {
    setReservationTypes: (state, reservationTypes) => {
      state.reservationTypes = reservationTypes;
    },

    addNumberPersons: (state, val) => {
      state.numberPersons = val;
    },

    addReservationType: (state, reservationTypeUuid) => {
      state.reservationTypeUuid = reservationTypeUuid;

      state.reservationTypes.forEach((reservationTypes) => {
        if (reservationTypes.uuid == reservationTypeUuid) {
          state.reservationTypeName = reservationTypes.name;

          state.reservationTypeMaxTime = reservationTypes.maxtime;

          state.maxPersons = reservationTypes.maxPerson;
        }
      });
    },
  },
  /*End mutations*/

  /*Start actions*/
  actions: {
    /*Start fetch reservation-types*/
    async fetchReservationTypes({ commit }) {
      window.store.commit("loader/changeStatus", true); //start global loading

      await window.axios
        .get(ENDPOINTS.ONLINE_SERVICES.RESERVATION_TYPES.GET_ALL)
        .then((response) => {
          commit("setReservationTypes", response.data.data);
        })
        .catch(() => {})
        .finally(function() {
          window.store.commit("loader/changeStatus", false); //stop global loading
        });
    },
    /*End fetch reservation-types*/
  },
  /*End actions*/
};

export default reservationTypes;
