import Vue from "vue";
import "@/plugins/axios";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import vuetify from "@/plugins/vuetify";
import "@/plugins/currency";
import VueI18n from "vue-i18n";
import messages from "./i18n";

import VueSweetalert2 from "vue-sweetalert2";

// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";

Vue.use(VueSweetalert2);

import { library } from "@fortawesome/fontawesome-svg-core";

import { fab } from "@fortawesome/free-brands-svg-icons";

import { fas } from "@fortawesome/pro-solid-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { fad } from "@fortawesome/pro-duotone-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(fab);
library.add(fal);
library.add(fad);
library.add(fas);
library.add(far);

Vue.component("font-awesome-icon", FontAwesomeIcon);

import "@/assets/css/index.css";

Vue.config.productionTip = false;

/**internationalization **/
Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: localStorage.getItem("lang") || "de",
  fallbackLocale: "de",
  silentTranslationWarn: true, //DISABLE WARNINGS
  messages,
});

/**flags */
import FlagIcon from "vue-flag-icon";
Vue.use(FlagIcon);

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
