import Vue from "vue";
import Vuex from "vuex";

/**
 * import modules
 */
import loader from "@/store/modules/loader/";
import config from "@/store/modules/config/";
import reservationTypes from "@/store/modules/reservationTypes/";
import dateTime from "@/store/modules/dateTime/";
import tables from "@/store/modules/tables/";
import items from "@/store/modules/items/";
import customerData from "@/store/modules/customerData/";
import order from "@/store/modules/order/";

Vue.use(Vuex);

// --- Vuex Persist ---
import VuexPersistence from "vuex-persist";

// --- LocalForage ---
import localforage from "localforage";
localforage.config({});

const vuexLocal = new VuexPersistence({
  storage: localforage,
  asyncStorage: true,
});

/*const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});*/

const Store = new Vuex.Store({
  plugins: [vuexLocal.plugin],
  modules: {
    loader,
    config,
    reservationTypes,
    dateTime,
    tables,
    items,
    customerData,
    order,
  },
});

window.store = Store;
export default Store;
