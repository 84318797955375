/*import plugins*/
//import ENDPOINTS from "@/plugins/axios/endpoints";

const order = {
  namespaced: true,

  /*Start state*/
  state: {
    isOrder: false,
  },
  /*End state*/

  /*Start getters*/
  getters: {
    isOrder: (state) => state.isOrder,
  },
  /*End getters*/

  /*Start mutations*/
  mutations: {
    isOrder: (state, val) => {
      state.isOrder = val;
    },
  },
  /*End mutations*/

  /*Start actions*/
  actions: {
    /*Start add reservation*/
    createReservation() {
      window.store.commit("loader/changeStatus", true); //start global loading

      var items = [];
      window.store.state.items.selectedItems.forEach((selectedItem) => {
        items.push(selectedItem.itemId);
      });

      return new Promise((resolve, reject) => {
        window
          .axios({
            url: "/",
            method: "POST",
            headers: {
              contentType: "application/json",
            },
            data: {
              new_customer: {
                first_name: window.store.state.customerData.first_name,
                last_name: window.store.state.customerData.last_name,
                email: window.store.state.customerData.email,
                phone: window.store.state.customerData.phone,
              },
              type_uuid:
                window.store.state.reservationTypes.reservationTypeUuid,
              note: window.store.state.customerData.note,
              attachment: window.store.state.customerData.attachment,
              time: window.store.state.dateTime.time,
              date: window.store.state.dateTime.date,
              person: window.store.state.reservationTypes.numberPersons,
              tables: window.store.state.tables.tables,
              items: items,
            },
          })
          .then(function(response) {
            resolve(response);
          })
          .catch(function(error) {
            reject(error);
          })
          .finally(function() {
            window.store.commit("loader/changeStatus", false); //stop global loading
          });
      });
    },
    /*End add reservation*/
  },
  /*End actions*/
};

export default order;
