/*import plugins*/
import ENDPOINTS from "@/plugins/axios/endpoints";

const dateTime = {
  namespaced: true,

  /*Start state*/
  state: {
    date: new Date().toISOString().substr(0, 10),
    time: new Date().toISOString().substr(11, 3) + "00",

    openTimes: [],
    openDaysIndex: [],
    times: [],
  },
  /*End state*/

  /*Start getters*/
  getters: {
    openDaysIndex: (state) => {
      state.openTimes.forEach((openTime) => {
        state.openDaysIndex.push(openTime.day_index);
      });
      return state.openDaysIndex;
    },

    times: (state) => {
      var daySelect = new Date(state.date).getDay();

      var secTimeSteep = eval(window.store.state.config.timeSteep * 60);

      state.times = [];

      state.openTimes.forEach((openTime) => {
        if (
          eval(openTime.day_index - 1) === daySelect &&
          openTime.is_open === true
        ) {
          if (openTime.shift_1.is_open === true) {
            var shift1StartTime = eval(
              openTime.shift_1.start.substring(0, 2) * 3600 +
                openTime.shift_1.start.substring(3, 5) * 60
            );
            var shift1EndTime = eval(
              openTime.shift_1.end.substring(0, 2) * 3600 +
                openTime.shift_1.end.substring(3, 5) * 60
            );

            while (shift1StartTime < shift1EndTime) {
              let t = new Date(shift1StartTime * 1000);
              let hh = t
                .getUTCHours()
                .toString()
                .padStart(2, "0");
              let mm = t
                .getUTCMinutes()
                .toString()
                .padStart(2, "0");

              state.times.push(hh + ":" + mm);

              shift1StartTime = shift1StartTime + secTimeSteep;
            }
          }

          if (openTime.shift_2.is_open === true) {
            var shift2StartTime = eval(
              openTime.shift_2.start.substring(0, 2) * 3600 +
                openTime.shift_2.start.substring(3, 5) * 60
            );
            var shift2EndTime = eval(
              openTime.shift_2.end.substring(0, 2) * 3600 +
                openTime.shift_2.end.substring(3, 5) * 60
            );

            while (shift2StartTime < shift2EndTime) {
              let t = new Date(shift2StartTime * 1000);
              let hh = t
                .getUTCHours()
                .toString()
                .padStart(2, "0");
              let mm = t
                .getUTCMinutes()
                .toString()
                .padStart(2, "0");

              state.times.push(hh + ":" + mm);

              shift2StartTime = shift2StartTime + secTimeSteep;
            }
          }
        }
      });

      if (
        (Array.isArray(state.times) && state.times.length != 0) ||
        (state.times != null && state.times != "")
      ) {
        window.store.commit("config/isNextBtn", true);
      } else {
        window.store.commit("config/isNextBtn", false);
      }

      return state.times;
    },
  },
  /*End getters*/

  /*Start mutations*/
  mutations: {
    setOpenTimes: (state, openTimes) => {
      state.openTimes = openTimes;
    },

    addDate: (state, val) => {
      state.date = val;
    },

    addTime: (state, val) => {
      state.time = val;
    },
  },
  /*End mutations*/

  /*Start actions*/
  actions: {
    /*Start fetch opening-times*/
    async fetchOpenTimes({ commit }) {
      window.store.commit("loader/changeStatus", true); //start global loading

      await window.axios
        .get(ENDPOINTS.ONLINE_SERVICES.OPENING_TIMES.GET_ALL)
        .then((response) => {
          commit("setOpenTimes", response.data.data);
        })
        .catch(() => {})
        .finally(function() {
          window.store.commit("loader/changeStatus", false); //stop global loading
        });
    },
    /*End fetch opening-times*/
  },
  /*End actions*/
};

export default dateTime;
