<!--This component (step 1) to choose reservation type and number persons-->
<template>
  <v-container
    grid-list-sm
    fill-height
    :class="
      this.$vuetify.breakpoint.mdAndUp
        ? 'overflow-hidden justify-center'
        : 'ma-0 pa-0 overflow-hidden justify-center'
    "
  >
    <v-card
      elevation="0"
      tile
      :outlined="this.$vuetify.breakpoint.mdAndUp"
      :width="this.$vuetify.breakpoint.mdAndUp ? '75%' : '100%'"
    >
      <v-card-text>
        <v-row>
          <!--Start Welcome Content-->
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-card elevation="0" class="px-3">
              <div
                v-if="
                  this.config &&
                    this.config.welcome_page &&
                    this.config.welcome_page.welcome_title_status != null &&
                    this.config.welcome_page.welcome_title_status != false
                "
                class="py-6 text--center"
              >
                <div
                  v-html="config.welcome_page.welcome_title"
                  class="text-center text-h3"
                ></div>
              </div>

              <div
                v-if="
                  config.welcome_page &&
                    config.welcome_page.welcome_body_status != null &&
                    config.welcome_page.welcome_body_status != false
                "
                class="py-6 text-center"
              >
                <p
                  v-html="config.welcome_page.welcome_body"
                  class="text-center"
                ></p>
              </div>

              <div
                v-if="
                  config.welcome_page &&
                    config.welcome_page.welcome_alert_status != null &&
                    config.welcome_page.welcome_alert_status != false
                "
              >
                <v-alert
                  type="success"
                  :color="config.welcome_page.welcome_alert_color"
                  outlined
                  prominent
                  :value="true"
                >
                  {{ config.welcome_page.welcome_alert }}
                </v-alert>
              </div>
            </v-card>
          </v-col>
          <!--End Welcome Content-->

          <!--Start Reservation Types Card-->
          <v-col cols="12" sm="12" md="12" lg="12" class="mt-0 pt-0">
            <h3 class="font-weight-bold ml-3 mb-2">
              {{ $t("generic.lang_Reservation_type") }}
            </h3>

            <!--loader-->
            <div class="text-center">
              <v-progress-circular
                indeterminate
                v-if="loading"
                color="primary"
                size="128"
              />
            </div>

            <v-item-group
              v-model="reservationTypeUuid"
              class="fade-transition-enter-active"
              mandatory
            >
              <v-container class="pt-1">
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                    lg="6"
                    v-for="reservationType in reservationTypes"
                    :key="reservationType.uuid"
                  >
                    <v-item
                      v-slot="{ active, toggle }"
                      :value="reservationType.uuid"
                    >
                      <div
                        style="border-style: solid; border-width: 2px; border-color: transparent;"
                        :class="active ? 'primary' : ''"
                      >
                        <v-card
                          :color="active ? 'primary lighten-5' : ''"
                          class="d-flex align-center"
                          height="100"
                          @click="toggle"
                          outlined
                          tile
                        >
                          <v-list-item three-line>
                            <v-list-item-avatar tile size="80">
                              <v-icon x-large :color="reservationType.color">
                                fa-{{ reservationType.icon }}
                              </v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title
                                class="font-weight-regular w-100"
                                width="100%"
                              >
                                {{ reservationType.name }}
                              </v-list-item-title>

                              <!--<v-list-item-subtitle>
                              Greyhound divisely hello coldly
                            </v-list-item-subtitle>

                            <v-list-item-subtitle></v-list-item-subtitle>-->
                            </v-list-item-content>
                          </v-list-item>
                        </v-card>
                      </div>
                    </v-item>
                  </v-col>
                </v-row>
              </v-container>
            </v-item-group>

            <!--***-->
          </v-col>
          <!--End Reservation Types Card-->

          <!--Start Number Persons input-->
          <v-col
            v-if="reservationTypeUuid != '' && reservationTypeUuid != null"
            cols="12"
            sm="12"
            md="12"
            lg="12"
            class="mb-0 pb-0"
          >
            <v-container>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  ref="numberPersons"
                  v-model="numberPersons"
                  :label="$t('generic.lang_number_persons')"
                  placeholder=""
                  outlined
                  type="Number"
                  :min="1"
                  :rules="rules"
                  :error-messages="errorMessages"
                >
                  <v-icon slot="prepend-inner" class="mr-4" color="primary">
                    mdi-account-group
                  </v-icon>
                </v-text-field>
              </v-form>
            </v-container>
          </v-col>
          <!--End Number Persons input-->
        </v-row>
      </v-card-text>

      <!--Start Next Button-->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="px-7"
          large
          tile
          elevation="0"
          color="success"
          :disabled="!isNextBtn"
          @click="step1Validate()"
        >
          {{ $t("generic.lang_next") }}
        </v-btn>
      </v-card-actions>
      <!--End Next Button-->
    </v-card>
  </v-container>
</template>

<script>
/*import plugins*/
import { createNamespacedHelpers } from "vuex";

export default {
  name: "Step1Component",

  data: () => ({
    valid: true,
    errorMessages: "",

    rules: [
      (v) => !!v || "This field is required",
      (v) => !isNaN(v),
      (v) => (v && v >= 1) || "number persons should be above 0",
    ],
  }),

  computed: {
    //map loader getters
    ...createNamespacedHelpers("config").mapGetters(["isNextBtn"]),
    ...createNamespacedHelpers("loader").mapGetters(["loading"]),
    ...createNamespacedHelpers("reservationTypes").mapGetters([
      "reservationTypes",
      "maxPersons",
    ]),

    reservationTypeUuid: {
      get() {
        return this.$store.state.reservationTypes.reservationTypeUuid;
      },
      set(val) {
        this.$store.commit("reservationTypes/addReservationType", val);
      },
    },

    numberPersons: {
      get() {
        return this.$store.state.reservationTypes.numberPersons;
      },
      set(val) {
        this.$store.commit("reservationTypes/addNumberPersons", val);
      },
    },

    config() {
      return this.$store.getters["config/config"] || {};
    },
  },

  watch: {
    numberPersons(val) {
      if (val > this.maxPersons) {
        this.errorMessages =
          "Max should not be above " + this.maxPersons + " person";
      } else if (val === "" || val === null) {
        this.errorMessages = "This field is required";
      } else if (val < 1) {
        this.errorMessages = "number persons should be above 0";
      } else {
        this.errorMessages = "";
      }
    },
  },

  methods: {
    step1Validate() {
      if (this.numberPersons <= this.maxPersons) {
        if (
          this.$refs.form.validate() === true &&
          this.reservationTypeUuid != "" &&
          this.reservationTypeUuid != null
        ) {
          return this.$router.push({ name: "steps", params: { step: "2" } });
        }
      }
    },
  },

  mounted() {
    this.$store.dispatch("reservationTypes/fetchReservationTypes");

    if (
      (Array.isArray(this.reservationTypes) &&
        this.reservationTypes.length != 0) ||
      this.reservationTypes != null ||
      this.reservationTypes != ""
    ) {
      this.$store.commit("config/isNextBtn", true);
    } else {
      this.$store.commit("config/isNextBtn", false);
    }
  },
};
</script>

<style>
/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
