/*import plugins*/
import ENDPOINTS from "@/plugins/axios/endpoints";

const items = {
  namespaced: true,

  /*Start state*/
  state: {
    menuTypeSelected: null,

    selectedItems: [],

    menuTypes: [],
    items: [],
  },
  /*End state*/

  /*Start getters*/
  getters: {
    menuTypes: (state) => state.menuTypes,

    items: (state) => state.items,

    menuTypeSelected: (state) => state.menuTypeSelected,

    quantity: (state) => {
      return (id) => {
        if (parseInt(id) <= 0) return 0;

        //search for requested item
        const search = state.selectedItems.find(
          (selectedItem) => selectedItem.itemId === parseInt(id)
        );

        if (search) return parseInt(search.itemQuantity);

        return 0; //return 0 if this item is not selected
      };
    },
  },
  /*End getters*/

  /*Start mutations*/
  mutations: {
    setMenuTypes: (state, menuTypes) => {
      state.menuTypes = menuTypes;
      //state.menuTypeSelected = menuTypes[0].uuid;
    },

    setItems: (state, items) => {
      state.items = items;
    },

    addMenuTypeSelected: (state, menuTypeSelected) => {
      state.menuTypeSelected = menuTypeSelected;
    },

    addSelectedItems: (state, selectedItems = {}) => {
      //check if the ordered item is object
      if (!(typeof selectedItems === "object")) return;
      //check if the order array is reserved
      if (!Array.isArray(state.selectedItems)) state.selectedItems = [];

      //check if this item is already exists
      const search = state.selectedItems.find(
        (selectedItem) => selectedItem.itemId === selectedItems.itemId
      );

      if (search) {
        /**
         * item exists, then we have to increment qty
         */
        //find item index
        const index = state.selectedItems.indexOf(search);

        if (index === -1) return;

        ++state.selectedItems[index].itemQuantity;
      } else {
        /**
         * item not found, then we have to push it as new item
         */
        selectedItems.itemQuantity = 1;
        state.selectedItems.push(selectedItems);
      }
    },

    minus: (state, id) => {
      //check if this item is exists
      const search = state.selectedItems.find(
        (selectedItem) => selectedItem.itemId === id
      );

      if (search) --search.itemQuantity;

      if (search.itemQuantity < 1) {
        //qty less than one so we have to remove this item
        const index = state.selectedItems.indexOf(search); // find item index

        if (index == -1) return; //item not found

        state.selectedItems.splice(index, 1); //remove item
      }
    },
  },
  /*End mutations*/

  /*Start actions*/
  actions: {
    /*Start fetch Menu-Types*/
    async fetchMenuTypes({ commit }) {
      window.store.commit("loader/changeStatus", true); //start global loading

      await window.axios
        .get(ENDPOINTS.ONLINE_SERVICES.MENU_TYPES.GET_ALL + "?paginate=false")
        .then((response) => {
          commit("setMenuTypes", response.data.data);
        })
        .catch(() => {})
        .finally(function() {
          window.store.commit("loader/changeStatus", false); //stop global loading
        });
    },
    /*End fetch Menu-Types*/

    /*Start fetch items*/
    async fetchItems({ commit }, menuTypeSelected) {
      window.store.commit("loader/changeStatus", true); //start global loading

      await window.axios
        .get(
          ENDPOINTS.ONLINE_SERVICES.RESERVATION_TYPES.GET_ALL +
            "/" +
            window.store.state.reservationTypes.reservationTypeUuid +
            "/items?filter[menu_type]=" +
            menuTypeSelected
        )
        .then((response) => {
          commit("setItems", response.data.data);
        })
        .catch(() => {})
        .finally(function() {
          window.store.commit("loader/changeStatus", false); //stop global loading
        });
    },
    /*End fetch items*/
  },
  /*End actions*/
};

export default items;
