import multiguard from "vue-router-multiguard";

//import localbee table middlewares
import config from "@/middlewares/config/index";

const routes = [
  {
    path: "/",
    name: "home",
    component: require("@/views/Home.vue").default,
    beforeEnter: multiguard([config]),
  },
  {
    path: "/steps/:step",
    name: "steps",
    component: () => import("@/views/steps/index.vue"),
    beforeEnter: multiguard([config]),
  },
  {
    path: "/booking-receipt",
    name: "bookingReceipt",
    component: () => import("@/views/bookingReceipt/index.vue"),
    beforeEnter: multiguard([config]),
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("@/views/termsAndConditions/Terms.vue"),
  },
  {
    path: "/conditions",
    name: "conditions",
    component: () => import("@/views/termsAndConditions/Conditions.vue"),
  },
  {
    path: "/missing-config",
    name: "missingConfig",
    component: () => import("@/views/missingConfig/index.vue"),
    beforeEnter: (to, from, next) => {
      window.store.dispatch("config/loadConfig").then(() => {
        let isReservationEnabled =
          window.store.getters["config/isReservationEnabled"];

        if (isReservationEnabled) {
          next("/");
        }

        next();
      });
    },
  },
  {
    path: "/404",
    name: "NotFound",
    component: () => import("@/views/notFound/index.vue"),
  },
  {
    path: "*",
    redirect: "/404",
  },
];

export default routes;
