/*import plugins*/
import ENDPOINTS from "@/plugins/axios/endpoints";

const config = {
  namespaced: true,

  /*Start state*/
  state: {
    isReservationEnabled: true,

    timeSteep: 0,

    isNextBtn: true,

    config: [],
  },
  /*End state*/

  /*Start getters*/
  getters: {
    config: (state) => state.config,

    isReservationEnabled: (state) => state.isReservationEnabled,

    timeSteep: (state) => state.timeSteep,

    isNextBtn: (state) => state.isNextBtn,
  },
  /*End getters*/

  /*Start mutations*/
  mutations: {
    setConfig: (state, config) => {
      state.config = config;

      state.isReservationEnabled =
        config && config.reservation && config.reservation.enabled;

      state.timeSteep = config && config.calendar && config.calendar.time_steep;
    },

    isReservationEnabled: (state, val) => {
      state.isReservationEnabled = val;
    },

    isNextBtn: (state, val) => {
      state.isNextBtn = val;
    },
  },
  /*End mutations*/

  /*Start actions*/
  actions: {
    /*Start fetch reservation-types*/
    async loadConfig({ commit }) {
      await window.axios
        .get(ENDPOINTS.ONLINE_SERVICES.CONFIG.GET_ALL)
        .then((response) => {
          commit("setConfig", response.data);
        })
        .catch(() => {});
    },
    /*End fetch reservation-types*/
  },
  /*End actions*/
};

export default config;
