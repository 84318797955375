export default function(to, from, next) {
  window.store.dispatch("config/loadConfig").then(() => {
    let isReservationEnabled =
      window.store.getters["config/isReservationEnabled"];

    if (!isReservationEnabled) {
      next("/missing-config");
    }

    return next();
  });
}
