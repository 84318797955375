const ENDPOINTS = {
  ONLINE_SERVICES: {
    CONFIG: {
      GET_ALL: "config",
    },
    RESERVATION_TYPES: {
      GET_ALL: "reservation-types",
    },
    OPENING_TIMES: {
      GET_ALL: "opening-times",
    },
    MENU_TYPES: {
      GET_ALL: "menu-types",
    },
  },
};

export default ENDPOINTS;
