const loader = {
  namespaced: true,

  state: {
    load: false,
  },

  getters: {
    loading: (state) => state.load,
  },

  mutations: {
    changeStatus: (state, val) => {
      state.load = val;
    },
  },
};

export default loader;
