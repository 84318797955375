var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{class:this.$vuetify.breakpoint.mdAndUp
      ? 'overflow-hidden justify-center'
      : 'ma-0 pa-0 overflow-hidden justify-center',attrs:{"grid-list-sm":"","fill-height":""}},[_c('v-card',{attrs:{"elevation":"0","tile":"","outlined":this.$vuetify.breakpoint.mdAndUp,"width":this.$vuetify.breakpoint.mdAndUp ? '75%' : '100%'}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('v-card',{staticClass:"px-3",attrs:{"elevation":"0"}},[(
                this.config &&
                  this.config.welcome_page &&
                  this.config.welcome_page.welcome_title_status != null &&
                  this.config.welcome_page.welcome_title_status != false
              )?_c('div',{staticClass:"py-6 text--center"},[_c('div',{staticClass:"text-center text-h3",domProps:{"innerHTML":_vm._s(_vm.config.welcome_page.welcome_title)}})]):_vm._e(),(
                _vm.config.welcome_page &&
                  _vm.config.welcome_page.welcome_body_status != null &&
                  _vm.config.welcome_page.welcome_body_status != false
              )?_c('div',{staticClass:"py-6 text-center"},[_c('p',{staticClass:"text-center",domProps:{"innerHTML":_vm._s(_vm.config.welcome_page.welcome_body)}})]):_vm._e(),(
                _vm.config.welcome_page &&
                  _vm.config.welcome_page.welcome_alert_status != null &&
                  _vm.config.welcome_page.welcome_alert_status != false
              )?_c('div',[_c('v-alert',{attrs:{"type":"success","color":_vm.config.welcome_page.welcome_alert_color,"outlined":"","prominent":"","value":true}},[_vm._v(" "+_vm._s(_vm.config.welcome_page.welcome_alert)+" ")])],1):_vm._e()])],1),_c('v-col',{staticClass:"mt-0 pt-0",attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('h3',{staticClass:"font-weight-bold ml-3 mb-2"},[_vm._v(" "+_vm._s(_vm.$t("generic.lang_Reservation_type"))+" ")]),_c('div',{staticClass:"text-center"},[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"128"}}):_vm._e()],1),_c('v-item-group',{staticClass:"fade-transition-enter-active",attrs:{"mandatory":""},model:{value:(_vm.reservationTypeUuid),callback:function ($$v) {_vm.reservationTypeUuid=$$v},expression:"reservationTypeUuid"}},[_c('v-container',{staticClass:"pt-1"},[_c('v-row',_vm._l((_vm.reservationTypes),function(reservationType){return _c('v-col',{key:reservationType.uuid,attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('v-item',{attrs:{"value":reservationType.uuid},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var active = ref.active;
              var toggle = ref.toggle;
return [_c('div',{class:active ? 'primary' : '',staticStyle:{"border-style":"solid","border-width":"2px","border-color":"transparent"}},[_c('v-card',{staticClass:"d-flex align-center",attrs:{"color":active ? 'primary lighten-5' : '',"height":"100","outlined":"","tile":""},on:{"click":toggle}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-avatar',{attrs:{"tile":"","size":"80"}},[_c('v-icon',{attrs:{"x-large":"","color":reservationType.color}},[_vm._v(" fa-"+_vm._s(reservationType.icon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-regular w-100",attrs:{"width":"100%"}},[_vm._v(" "+_vm._s(reservationType.name)+" ")])],1)],1)],1)],1)]}}],null,true)})],1)}),1)],1)],1)],1),(_vm.reservationTypeUuid != '' && _vm.reservationTypeUuid != null)?_c('v-col',{staticClass:"mb-0 pb-0",attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('v-container',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{ref:"numberPersons",attrs:{"label":_vm.$t('generic.lang_number_persons'),"placeholder":"","outlined":"","type":"Number","min":1,"rules":_vm.rules,"error-messages":_vm.errorMessages},model:{value:(_vm.numberPersons),callback:function ($$v) {_vm.numberPersons=$$v},expression:"numberPersons"}},[_c('v-icon',{staticClass:"mr-4",attrs:{"slot":"prepend-inner","color":"primary"},slot:"prepend-inner"},[_vm._v(" mdi-account-group ")])],1)],1)],1)],1):_vm._e()],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"px-7",attrs:{"large":"","tile":"","elevation":"0","color":"success","disabled":!_vm.isNextBtn},on:{"click":function($event){return _vm.step1Validate()}}},[_vm._v(" "+_vm._s(_vm.$t("generic.lang_next"))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }