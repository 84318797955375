import "@fortawesome/fontawesome-pro/css/all.css";
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.deepOrange,
        secondary: colors.grey.lighten3,
        accent: colors.grey.lighten1,
        success: colors.green,
        warning: colors.orange,
        error: colors.red,
        info: colors.blue,
        titleColor: colors.black,
      },
    },
  },

  icons: {
    iconfont: "mdi" || "md" || "fa" || "fal",
  },
});
