const customerData = {
  namespaced: true,

  /*Start state*/
  state: {
    first_name: null,
    last_name: null,
    phone: null,
    email: null,
    note: "",
    attachment: "",
    checkbox: false,
  },
  /*End state*/

  /*Start mutations*/
  mutations: {
    addFirstName: (state, val) => {
      state.first_name = val;
    },

    addLastName: (state, val) => {
      state.last_name = val;
    },

    addPhone: (state, val) => {
      state.phone = val;
    },

    addEmail: (state, val) => {
      state.email = val;
    },

    addNote: (state, val) => {
      state.note = val;
    },

    addAttachment: (state, val) => {
      state.attachment = val;
    },

    addCheckbox: (state, val) => {
      state.checkbox = val;
    },
  },
  /*End mutations*/
};

export default customerData;
