<template>
  <!--Start Home Page-->

  <!--Start Step1-->
  <step-1-component />
  <!--End Step1-->

  <!--End Home Page-->
</template>

<script>
/*import plugins*/
import Step1Component from "@/components/home/Step1Component.vue";

export default {
  name: "Home",

  components: { Step1Component },
};
</script>
