<template>
  <v-app>
    <!--start main content-->
    <v-main>
      <router-view />
    </v-main>
    <!--end main content-->

    <v-footer
      id="dontPrint"
      class="mx-auto text-center justify-center align-center d-flex mt-10 py-6"
      :width="this.$vuetify.breakpoint.mdAndUp ? '100%' : '100%'"
    >
      <!--start copy right-->
      <div class="text-center font-weight-regular w-100">
        <span
          class="text-center text-muted font-size-md"
          style="font-size: 12px !important;"
        >
          {{ $t("generic.lang_footer_made_with") }}
          <a
            href="https://www.3pos.de"
            style="color: #f84611; cursor: pointer; text-decoration: none;"
          >
            3POS
          </a>
          Kassensystem
        </span>
      </div>
      <!--end copy right-->

      <v-spacer />

      <!--start change language-->
      <div class="text-right w-100">
        <v-btn @click="changeLocale('en')" v-if="this.language === 'de'" text>
          <flag style="font-size: 30px" iso="us" />
        </v-btn>

        <v-btn @click="changeLocale('de')" v-if="this.language === 'en'" text>
          <flag style="font-size: 30px" iso="de" />
        </v-btn>
      </div>
      <!--end change language-->
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  computed: {
    language: {
      get() {
        return this.$i18n.locale;
      },
      set(val) {
        this.$i18n.locale = val;
      },
    },
  },

  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale;
      this.$forceUpdate();
    },
  },

  mounted() {
    window.store.dispatch("config/loadConfig");
  },
};
</script>

<style lang="scss">
@media print {
  #dontPrint {
    display: none !important;
  }
}
</style>
