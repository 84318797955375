/*import plugins*/
import ENDPOINTS from "@/plugins/axios/endpoints";

const items = {
  namespaced: true,

  /*Start state*/
  state: {
    tables: [],

    rooms: [],
  },
  /*End state*/

  /*Start getters*/
  getters: {
    rooms: (state) => state.rooms,
  },
  /*End getters*/

  /*Start mutations*/
  mutations: {
    setRooms: (state, rooms) => {
      state.rooms = rooms;
    },

    addTables: (state, val) => {
      state.tables = val;
    },
  },
  /*End mutations*/

  /*Start actions*/
  actions: {
    /*Start fetch available-tables*/
    async fetchRooms({ commit }) {
      window.store.commit("loader/changeStatus", true); //start global loading

      await window.axios
        .get(
          ENDPOINTS.ONLINE_SERVICES.RESERVATION_TYPES.GET_ALL +
            "/" +
            window.store.state.reservationTypes.reservationTypeUuid +
            "/available-tables",
          {
            params: {
              day: window.store.state.dateTime.date,
              time: window.store.state.dateTime.time,
              persons: window.store.state.reservationTypes.numberPersons,
            },
          }
        )
        .then((response) => {
          commit("setRooms", response.data);
        })
        .catch(() => {})
        .finally(function() {
          window.store.commit("loader/changeStatus", false); //stop global loading
        });
    },
    /*End fetch available-tables*/
  },
  /*End actions*/
};

export default items;
